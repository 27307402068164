import { NEXT_PUBLIC_AMPLITUDE_API_KEY } from '@/constants/envs';

interface Analytics {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  trackEvent: (name: string, data?: Record<string, any>) => void;
  initialize?: () => Promise<void>;
}

type Amplitude = typeof import('@amplitude/analytics-browser');

// eslint-disable-next-line import/no-unused-modules
export class AmplitudeAnalytics implements Analytics {
  apiKey: string;
  isInitialized = false;
  amplitude: Amplitude | undefined;

  constructor(apiKey: string) {
    this.apiKey = apiKey;
  }

  async initialize() {
    if (!this.isInitialized && typeof window !== 'undefined') {
      if (!this.amplitude) {
        this.amplitude = await import('@amplitude/analytics-browser');
      }

      if (this.isInitialized) return;

      this.isInitialized = true;

      this.amplitude.init(this.apiKey, {
        defaultTracking: {
          pageViews: true,
          sessions: false,
          formInteractions: false
        }
      });

      const pagePath = window.location.pathname;
      const pageDomain = window.location.hostname;
      const pageTitle = document.title;

      const identifyEvent = new this.amplitude.Identify()
        .set('Page Path', pagePath)
        .set('Page Domain', pageDomain)
        .set('Page Title', pageTitle);

      this.amplitude.identify(identifyEvent);

      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);

      const paramNames = ['utm_medium', 'utm_campaign', 'utm_source', 'utm_content'];
      paramNames.forEach((paramName) => {
        const paramValue = params.get(paramName);
        if (paramValue) {
          identifyEvent.set(paramName, paramValue);
        }
      });

      this.amplitude.identify(identifyEvent);

      this.isInitialized = true;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async trackEvent(name: string, data?: Record<string, any>) {
    await this.initialize();

    await this.amplitude?.track(name, {
      ...(data || {})
    }).promise;
  }
}

export const analytics =
  process.env.NODE_ENV === 'production' ? new AmplitudeAnalytics(NEXT_PUBLIC_AMPLITUDE_API_KEY) : null;
